import { useLazyQuery } from '@apollo/client';
import { message } from 'antd';
import { cloneDeep, filter, findIndex, isEmpty, kebabCase, map } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { AppContext } from '../AppContext';
import Error404 from '../Error404';
import analytics from '../common/analytics';
import api from '../common/api';
import {
  FIELD_CATEGORY,
  SPONSOR_KEY,
  TENANT_KEY,
  WIDGET_TYPES,
} from '../common/constants';
import {
  createCustomFieldObject,
  createMetaObj,
  createSystemFieldObject,
  getCurrentQuoteObject,
  googleAnalyticsParams,
  isPageSkip,
  updateDataMutation,
} from '../common/utils';
import CommonConfigComponent from '../components/CommonConfigComponent';
import DynamicScriptsInjector from '../components/DynamicScriptsInjector';
import LoaderComponent from '../components/LoaderComponent';
import {
  GET_LEAD_STORE_DATA,
  GET_PAGE_SEQUENCES,
  VALIDATE_ZIP_CODE,
} from '../graphql/Query';

function getQuery() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { search = '' } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useMemo(() => new URLSearchParams(search), [search]);
}

const ContentRoutes = () => {
  const {
    dispatch,
    state: { loading, storedData, subDomain, isSponsor },
  } = useContext(AppContext);

  const [pageSequencesData, setPageSequencesData] = useState([]);
  const [pageSequencesSet, setPageSequencesSet] = useState(false);
  const [analyticsData, setAnalyticsData] = useState([]);
  const portalIdentifier = isSponsor ? SPONSOR_KEY : TENANT_KEY;

  const [isParamsAvailable, setIsParamsAvailable] = useState(false);

  const [urlParamsWithConfig, setUrlParamsWithConfig] = useState(null); // use for appending project data ,system fields and custom fields data which will be extracted from URL

  const [urlParams, setUrlParams] = useState(null); // used to extract and store query params, we need to pass in final mutation e.g

  const query = getQuery();
  const { slug } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [getLeadStoreData] = useLazyQuery(GET_LEAD_STORE_DATA, {});

  const [validateZipCode] = useLazyQuery(VALIDATE_ZIP_CODE, {
    fetchPolicy: 'network-only',
    onCompleted() {},
    onError() {},
  });

  const [getSecondaryPageSequence] = useLazyQuery(GET_PAGE_SEQUENCES, {
    fetchPolicy: 'network-only',
    onCompleted() {},
    onError() {},
  });

  const getLocationFromPostalCode = async (zipCode) => {
    if (zipCode) {
      try {
        const postalValidation = await api?.get('/map-api', {
          params: {
            address: zipCode,
          },
        });
        if (postalValidation?.data?.results?.length > 0) {
          const validRes = await validateZipCode({
            variables: {
              where: {
                slug,
                subDomain,
                zipCode,
                portalIdentifier,
              },
            },
          });
          if (validRes?.data) {
            const address =
              postalValidation?.data?.results?.[0]?.formatted_address;

            if (
              address?.slice(address?.length - 3, address?.length) === 'USA'
            ) {
              const {
                lat,
                lng,
              } = postalValidation?.data?.results?.[0]?.geometry?.location;
              let cityShortName;
              let stateShortName;
              let cityLongName;
              let stateLongName;
              const localityIndex = findIndex(
                postalValidation?.data?.results?.length > 0 &&
                  postalValidation?.data?.results?.[0]?.address_components,
                (item) => item?.types?.includes('locality'),
              );
              for (
                let i = 0;
                i <
                postalValidation?.data?.results?.[0]?.address_components
                  ?.length;
                // eslint-disable-next-line no-plusplus
                i++
              ) {
                for (
                  let j = 0;
                  j <
                  postalValidation?.data?.results?.[0]?.address_components?.[i]
                    ?.types?.length;
                  // eslint-disable-next-line no-plusplus
                  j++
                ) {
                  switch (
                    postalValidation?.data?.results?.[0]?.address_components?.[
                      i
                    ]?.types?.[j]
                  ) {
                    case 'locality':
                      if (localityIndex > -1) {
                        cityLongName =
                          postalValidation?.data?.results?.[0]
                            ?.address_components?.[i]?.long_name;
                        cityShortName =
                          postalValidation?.data?.results?.[0]
                            ?.address_components?.[i]?.short_name;
                      }
                      break;
                    case 'neighborhood':
                      if (localityIndex < 0) {
                        cityLongName =
                          postalValidation?.data?.results?.[0]
                            ?.address_components?.[i]?.long_name;
                        cityShortName =
                          postalValidation?.data?.results?.[0]
                            ?.address_components?.[i]?.short_name;
                      }
                      break;
                    case 'administrative_area_level_1':
                      stateLongName =
                        postalValidation?.data?.results?.[0]
                          ?.address_components?.[i]?.long_name;
                      stateShortName =
                        postalValidation?.data?.results?.[0]
                          ?.address_components?.[i]?.short_name;
                      break;

                    default:
                      break;
                  }
                }
              }
              dispatch({
                type: 'SET_SHOW_SUBMIT_BTN',
                data: true,
              });
              dispatch({
                type: 'SET_LOCATION_NAME',
                data: `${cityShortName}, ${stateShortName}`,
              });
              const postalCodeObj = {
                lat,
                lng,
                city: cityLongName,
                state: stateLongName,
                zipCode,
                isZipCodeValid: validRes?.data?.validateZipCode,
                shortAddress: `${cityShortName}, ${stateShortName}`,
              };

              return { ...postalCodeObj };
            }
          }
        }
      } catch (error) {
        message.error(error);
      }
    }
  };

  const setCurrentPage = async (queryParameters = null) => {
    let visitorId;
    if (queryParameters?.visitorId) {
      visitorId = queryParameters?.visitorId;
    } else {
      // eslint-disable-next-line no-undef
      const wsmTracker = window?.Wsm?.getAsyncTracker();
      visitorId = await wsmTracker?.getVisitorUniqueId();
    }
    let urlParamsClone = { ...queryParameters };
    let metaData;
    let newPageSequence = [...pageSequencesData]
      ?.map((page, index) => {
        if (
          page?.pageConfiguration?.widgetType === WIDGET_TYPES?.SUPPORT &&
          queryParameters?.isZipCodeValid
        ) {
          return;
        }
        return {
          ...page,
          order: index + 1,
        };
      })
      ?.filter((pageData) => pageData); // for removing null objects from;

    if (!isEmpty(urlParamsClone)) {
      if (urlParamsClone?.zipCode) {
        metaData = {
          ...metaData,
          'postal-code': urlParamsClone?.zipCode || storedData?.zipCode,
        };
      }
      if (urlParamsClone?.lobObject || urlParamsClone?.serviceTypeObject) {
        metaData = {
          ...metaData,
          lob: urlParamsClone?.lobObject,
          sa: urlParamsClone?.serviceTypeObject,
        };
      }
    }

    urlParamsClone = {
      ...urlParamsClone,
      ...(metaData && {
        metaData,
      }),
    };

    try {
      const data = await getLeadStoreData({
        variables: {
          where: {
            visitorId,
          },
        },
      });

      const supportWidgetIndex = newPageSequence?.findIndex(
        (page) => page?.pageConfiguration?.widgetType === WIDGET_TYPES?.SUPPORT,
      );
      const quoteWidget = newPageSequence?.find(
        (page) => page?.pageConfiguration?.widgetType === WIDGET_TYPES?.QUOTES,
      );
      const projectWidgetIndex = findIndex(
        newPageSequence,
        (item) => item?.pageConfiguration?.widgetType === WIDGET_TYPES?.PROJECT,
      );

      const currentQuoteObj = getCurrentQuoteObject(
        data?.data?.getLeadStoreData?.data,
      );
      dispatch({
        type: 'SET_TOTAL_PROJECT_COUNT',
        data: filter(
          data?.data?.getLeadStoreData?.data,
          (project) => project?.data?.productTitle,
        )?.length,
      });

      const secondaryId =
        currentQuoteObj?.data?.navigateTo ||
        urlParamsClone?.serviceTypeObject?.navigateTo;

      if (secondaryId) {
        const { data: res } = await getSecondaryPageSequence({
          query: GET_PAGE_SEQUENCES,
          variables: {
            where: {
              id: secondaryId,
              // TODO: Need to make this dynamic once the difference between sponsor  and tenant is distinguished
              portalIdentifier: isSponsor ? SPONSOR_KEY : TENANT_KEY,
              ...(urlParamsWithConfig && { urlParams: urlParamsWithConfig }),
            },
          },
          fetchPolicy: 'network-only',
        });

        const queryParamsKey =
          (urlParamsClone && Object?.keys(urlParamsClone)) || [];

        let systemFields;
        let customFields;
        const secondaryData = map([...res?.pageSequences?.data], (item) => {
          const widgetType = item?.pageConfiguration.widgetType;
          const pageKey = item?.pageKey;
          const isPageAlwaysSkip =
            item?.pageConfiguration?.skipPageConditions?.alwaysSkip;
          const skipPageConditions = item?.pageConfiguration?.skipPageConditions?.data?.filter(
            (skipPageCond) => skipPageCond?.check,
          );
          const isSkip = isPageSkip(
            isPageAlwaysSkip,
            skipPageConditions,
            customFields,
            systemFields,
          );

          if (isParamsAvailable) {
            if (isSkip) {
              return {
                ...item,
                isSecondaryPage: true,
                brandConfig:
                  pageSequencesData?.[supportWidgetIndex]?.brandConfig,
                cutOffTime: pageSequencesData?.[supportWidgetIndex]?.cutOffTime,
                tenantLogo: pageSequencesData?.[supportWidgetIndex]?.tenantLogo,
                logo: pageSequencesData?.[supportWidgetIndex]?.logo,
                primaryColor:
                  pageSequencesData?.[supportWidgetIndex]?.primaryColor,
                canSkip: true,
              };
            }

            if (widgetType === WIDGET_TYPES?.CONTENT) {
              metaData = {
                ...metaData,
                [pageKey]: true,
              };
            }
            if (
              queryParamsKey?.includes(item?.pageKey) &&
              widgetType !== WIDGET_TYPES?.CONTENT // content pages are skipped
            ) {
              if (widgetType === FIELD_CATEGORY?.CUSTOM) {
                const newCustomField = createCustomFieldObject(
                  item,
                  urlParamsClone,
                );
                metaData = {
                  ...metaData,
                  [pageKey]: createMetaObj(
                    newCustomField,
                    FIELD_CATEGORY?.CUSTOM,
                  ),
                };
                customFields = {
                  ...customFields,
                  ...newCustomField,
                };
              }
              if (widgetType === FIELD_CATEGORY?.SYSTEM) {
                const newSystemField = createSystemFieldObject(
                  item,
                  urlParamsClone,
                );
                metaData = {
                  ...metaData,
                  [pageKey]: createMetaObj(
                    newSystemField,
                    FIELD_CATEGORY?.SYSTEM,
                  ),
                };

                if (newSystemField) {
                  systemFields = {
                    ...systemFields,
                    ...newSystemField,
                  };
                }
              }
            }
          }
          return {
            ...item,
            isSecondaryPage: true,
            brandConfig: pageSequencesData?.[supportWidgetIndex]?.brandConfig,
            cutOffTime: pageSequencesData?.[supportWidgetIndex]?.cutOffTime,
            tenantLogo: pageSequencesData?.[supportWidgetIndex]?.tenantLogo,
            logo: pageSequencesData?.[supportWidgetIndex]?.logo,
            primaryColor: pageSequencesData?.[supportWidgetIndex]?.primaryColor,
          };
        })
          ?.filter((page) => !page?.pageSkipFromParams)
          ?.filter((page) => !page?.canSkip);

        urlParamsClone = {
          ...urlParamsClone,
          ...(systemFields && {
            systemFields,
          }),
          ...(metaData && {
            metaData,
          }),
          ...(customFields && {
            customFields,
          }),
        };

        setUrlParamsWithConfig({
          ...urlParamsClone,
          systemFields,
          customFields,
        });

        // need to handle a refresh scenario
        if (secondaryData?.length > 0) {
          // need to check here , change condtion as newPageSequence set in else it casue issue of we pass aall params correct
          const pageSequenceDataCopy = cloneDeep(newPageSequence);

          if (!urlParamsClone?.serviceTypeObject?.navigateTo) {
            // adding secondary data after project widget
            newPageSequence?.splice(
              projectWidgetIndex + 1,
              0,
              ...secondaryData,
            );
            setPageSequencesSet(false);
            const filteredNewPageSequence = newPageSequence
              ?.filter((pageData) => !pageData?.pageSkipFromParams) // for removing skipped pages
              ?.map((item, index) => ({
                ...item,
                order: index + 1,
              })); // for managing order

            setPageSequencesData(filteredNewPageSequence);
            newPageSequence = [...filteredNewPageSequence];
            const updatedData = {
              ...storedData,
              ...(currentQuoteObj?.id && { id: currentQuoteObj?.id }),
              isSecondaryAdded: true,
            };
            await updateDataMutation(updatedData, dispatch, visitorId);
          } else {
            pageSequenceDataCopy?.splice(
              projectWidgetIndex + 1,
              0,
              ...secondaryData,
            );
            setPageSequencesSet(false);

            const updatedPageSequence = pageSequenceDataCopy
              ?.filter((pageData) => !pageData?.pageSkipFromParams) // for removing skipped pages
              ?.map((item, index) => ({
                ...item,
                order: index + 1,
              })); // for managing order
            // skip first then change page order
            setPageSequencesData([...updatedPageSequence]);
            newPageSequence = [...updatedPageSequence];
          }
        } else {
          setPageSequencesSet(false);
          const filteredNewPageSequence = newPageSequence
            ?.filter((pageData) => !pageData?.pageSkipFromParams) // for removing skipped pages
            ?.map((item, index) => ({
              ...item,
              order: index + 1,
            }));
          setPageSequencesData([...filteredNewPageSequence]);
          newPageSequence = [...filteredNewPageSequence];
        }
      } else {
        setPageSequencesSet(false);
        const filteredNewPageSequence = newPageSequence
          ?.filter((pageData) => !pageData?.pageSkipFromParams) // for removing skipped pages
          ?.map((item, index) => ({
            ...item,
            order: index + 1,
          }));
        setPageSequencesData([...filteredNewPageSequence]);
        newPageSequence = [...filteredNewPageSequence];
      }

      if (currentQuoteObj?.data?.currentPage) {
        if (!urlParamsClone?.visitorId && !urlParamsClone?.leadId) {
          // NORMAl FLOW
          dispatch({
            type: 'SET_STORED_DATA',
            data: {
              ...currentQuoteObj?.data,
              ...(currentQuoteObj?.id && { id: currentQuoteObj?.id }),
            },
          });

          // fetchstep util function call here
          dispatch({
            type: 'SET_LOADING',
            data: false,
          });

          // now we are checking if the current page key is exist in page sequence or not
          // as tenant admin can change the page key any time so instead of showing 404
          // we are redirecting user to first page of page sequence
          let pageKey = null;
          if (newPageSequence?.length > 0) {
            const isPageExist = newPageSequence?.find(
              (page) => page?.pageKey === currentQuoteObj?.data?.currentPage,
            );

            if (!isPageExist) {
              pageKey = newPageSequence?.[0]?.pageKey;
            } else {
              pageKey = currentQuoteObj?.data?.currentPage;
            }
          }
          navigate(
            `/${slug}/${kebabCase(pageKey)}${googleAnalyticsParams(
              urlParamsClone,
            )}`,
            {
              replace: true,
              state: {
                urlParams: { ...urlParamsClone },
              },
            },
          );
        } else {
          // CHANGE MY QUOTE FLOW
          dispatch({
            type: 'SET_STORED_DATA',
            data: {
              ...currentQuoteObj?.data,
              ...(currentQuoteObj?.id && { id: currentQuoteObj?.id }),
              ...(!isEmpty(urlParamsClone) && {
                urlParams: urlParamsClone,
              }),
              ...(urlParamsClone?.leadId && {
                leadId: urlParamsClone?.leadId,
              }),
              ...(urlParamsClone?.visitorId && {
                visitorId: urlParamsClone?.visitorId,
              }),
              currentPage: quoteWidget?.pageKey,
              currentOrder: quoteWidget?.order,
              previousPageKey: null,
              previousPageTitle: null,
            },
          });
          dispatch({
            type: 'SET_LOADING',
            data: false,
          });
          navigate(
            `/${slug}/${kebabCase(quoteWidget?.pageKey)}${googleAnalyticsParams(
              {
                ...urlParamsClone,
                ...urlParams,
              },
            )}`,
            {
              ...(isParamsAvailable && {
                state: {
                  urlParams: {
                    ...urlParamsClone,
                    ...urlParams,
                  },
                },
              }),
            },
          );
        }
      } else {
        // if facing any issue in pagesequence in normal flow then change below line to
        // let firstOrderObj = pageSequencesData?.[0]; we are using newPageSequence here because we are removing support widget,
        // if postal code (which is passed in URL) is correct

        let firstOrderObj = newPageSequence?.[0];
        dispatch({
          type: 'SET_LOADING',
          data: false,
        });

        if (
          urlParamsClone?.['postal-code'] &&
          !urlParamsClone?.isZipCodeValid
        ) {
          firstOrderObj = newPageSequence?.find(
            (page) =>
              page?.pageConfiguration?.widgetType === WIDGET_TYPES?.SUPPORT,
          );
        }
        if (
          firstOrderObj?.pageConfiguration?.widgetType ===
            WIDGET_TYPES?.SUPPORT &&
          urlParamsClone &&
          urlParamsClone?.isZipCodeValid
        ) {
          firstOrderObj = newPageSequence?.[supportWidgetIndex + 1];
          navigate(
            `/${slug}/${kebabCase(
              firstOrderObj?.pageKey,
            )}${googleAnalyticsParams({
              ...urlParamsClone,
              ...urlParams,
            })}`,
            {
              ...(isParamsAvailable && {
                state: {
                  urlParams: {
                    ...urlParamsClone,
                    ...urlParams,
                  },
                },
              }),
            },
          );
        }

        navigate(
          `/${slug}/${kebabCase(firstOrderObj?.pageKey)}${googleAnalyticsParams(
            {
              ...urlParamsClone,
              ...urlParams,
            },
          )}`,
          {
            ...(isParamsAvailable && {
              state: {
                urlParams: { ...urlParamsClone, urlParams },
              },
            }),
          },
        );
      }
    } catch (error) {
      dispatch({
        type: 'SET_LOADING',
        data: false,
      });
      setPageSequencesSet(false);
      return error;
    }
  };

  const [pageSequences] = useLazyQuery(GET_PAGE_SEQUENCES, {
    fetchPolicy: 'network-only',
    onCompleted: async (res) => {
      const selectedFromRest = res?.pageSequences?.selectedFromRest;
      setAnalyticsData(res?.pageSequences?.analyticsServiceProviders);
      const newPageSequence = res?.pageSequences?.data?.map((pageItem) => {
        if (
          pageItem?.pageConfiguration?.widgetType === WIDGET_TYPES?.PROJECT &&
          pageItem?.pageSkipFromParams
        ) {
          const urlParamsClone = {
            ...selectedFromRest, // these are the values from the restApi but as discussed we are given high priority to URL parameters.
            ...urlParamsWithConfig,
          };
          // what if user change a value and we didn't find secondary questionnaire data
          const lobObject = pageItem?.pageConfiguration?.widgetConfiguration?.otherConfig
            ?.map((lobItem) => {
              if (lobItem?.label === urlParamsClone?.lob) {
                return {
                  ...lobItem,
                };
              }
              return false;
            })
            ?.filter((item) => item);
          const serviceTypeObject = lobObject?.[0]?.subAreas?.find(
            (subAreaObj) => subAreaObj?.label === urlParamsClone?.sa,
          );
          const projectData = {
            lobObject: lobObject?.[0],
            serviceTypeObject,
          };
          setUrlParamsWithConfig({ ...urlParamsClone, ...projectData });
        }
        return {
          ...pageItem,
        };
      });
      setPageSequencesData(
        map(newPageSequence, (item, index) => ({
          ...item,
          order: index + 1,
        })),
      );
      const userId =
        res?.pageSequences?.data?.[0]?.[isSponsor ? 'sponsorId' : 'tenantId'];
      // eslint-disable-next-line no-undef
      const wsmTracker = window?.Wsm?.getAsyncTracker();
      // initialize tracker
      wsmTracker?.setTenantId(userId);
      wsmTracker?.setTrackerUrl(process.env.REACT_APP_ANALYTICS_URL);
      wsmTracker?.setCookiePath(slug);
      wsmTracker?.setSubDomain(subDomain);
      // setting primary color
      if (res?.pageSequences?.data?.[0]?.primaryColor) {
        dispatch({
          type: 'SET_PRIMARY_COLOR',
          data: res?.pageSequences?.data?.[0]?.primaryColor,
        });
      }
      setPageSequencesSet(true);
    },
    onError: () => {
      dispatch({
        type: 'SET_LOADING',
        data: false,
      });
    },
  });

  useEffect(() => {
    async function callZipCodeValidation() {
      if (urlParamsWithConfig?.['postal-code']) {
        let queryParamsClone = { ...urlParamsWithConfig };
        const postalValidationRes = await getLocationFromPostalCode(
          urlParamsWithConfig?.['postal-code'],
        );

        if (!isEmpty(postalValidationRes)) {
          queryParamsClone = {
            ...queryParamsClone,
            ...postalValidationRes,
          };

          setUrlParamsWithConfig({
            ...urlParamsWithConfig,
            ...queryParamsClone,
          });
        }
        setCurrentPage(queryParamsClone);
      } else {
        setCurrentPage(urlParamsWithConfig);
      }
    }

    if (pageSequencesData?.length > 0 && pageSequencesSet) {
      if (!isParamsAvailable) {
        setCurrentPage();
      } else {
        callZipCodeValidation();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSequencesData, pageSequencesSet]);

  useEffect(() => {
    // clearCookies();
    // analytics code
    // eslint-disable-next-line no-undef
    if (!window.Wsm.initialized) {
      analytics?.newTracker(process.env.REACT_APP_ANALYTICS_URL);
    }
    dispatch({ type: 'SET_SLUG', data: slug });

    let urlParamsClone = null;

    if (!isEmpty(Object?.fromEntries(new URLSearchParams(location?.search)))) {
      urlParamsClone = Object?.fromEntries(
        new URLSearchParams(location?.search),
      );
    } else {
      urlParamsClone = location?.state?.urlParams;
    }

    const customerLeadID = query?.get('leadId') || urlParamsClone?.leadId;
    if (customerLeadID) {
      // set a dynamic visitor Id in analytics for change quote scenario
      // eslint-disable-next-line no-undef
      const wsmTracker = window?.Wsm?.getAsyncTracker();
      wsmTracker?.setVisitorFromBE(urlParamsClone?.visitorId);
    }

    // we can add flag based on visitor id and lead id to avoid refresh scenario issue

    if (!isEmpty(urlParamsClone)) {
      setUrlParamsWithConfig({ ...urlParamsWithConfig, ...urlParamsClone }); // set parameters in state for further use
      setUrlParams(urlParamsClone);
      setIsParamsAvailable(true);
    } else {
      setIsParamsAvailable(false);
    }

    pageSequences({
      variables: {
        where: {
          slug,
          portalIdentifier: isSponsor ? SPONSOR_KEY : TENANT_KEY,
          subDomain,
          ...(!isEmpty(urlParamsClone) && { urlParams: urlParamsClone }), // need to check this condition here whether send or not send
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <LoaderComponent />;
  }

  return (
    <>
      <Routes>
        {map(pageSequencesData, (item) => (
          <Route
            key={item?.id}
            path={`/${kebabCase(item?.pageKey)}`}
            element={
              <CommonConfigComponent
                pageSequencesData={pageSequencesData}
                setPageSequencesData={setPageSequencesData}
                pageKey={item?.pageKey}
                key={item?.id}
                title={item?.title}
              />
            }
          />
        ))}
        {/* commented this code this can be use in future . currently change quote functionality is changed.(this is router v4 code)  */}
        {/* <Route
          path={`${ROUTES?.SLUG}${ROUTES?.CHANGE_QUOTE}`}
          render={() => <ChangeQuoteWrapper quotesData={quotesData} />}
        /> */}
        <Route path="*" element={<Error404 />} />
      </Routes>
      {analyticsData?.length > 0 && (
        <DynamicScriptsInjector analyticsData={analyticsData} />
      )}
    </>
  );
};

export default ContentRoutes;

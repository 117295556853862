import { LoadingOutlined } from '@ant-design/icons';
import * as Sentry from '@sentry/react';
import { ConfigProvider, Spin } from 'antd';
import React, { useContext } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AppContext } from './AppContext';
import Error404 from './Error404';
import App from './app/App';
import { ROUTES } from './common/constants';
import MaintenancePage from './components/MaintenancePage';
import { history } from './historyData';

const RoutesWrapper = () => {
  const {
    state: { primaryColor },
  } = useContext(AppContext);
  const MyFallbackComponent = ({ error, componentStack }) => (
    <div>
      <p>
        <strong>Oops! An error occured!</strong>
      </p>
      <p>Below is the details…</p>
      <p>
        <strong>Error:</strong> {error?.toString()}
      </p>
      <p>
        <strong>Stacktrace:</strong> {componentStack}
      </p>
    </div>
  );

  // use this variable from envs so that we can able to run maintenance page on runtime.
  const maintenance = process.env.REACT_APP_MAINTENANCE_ENABLE;

  Spin?.setDefaultIndicator(<LoadingOutlined className="font-size-32" spin />);

  if (maintenance === 'true') {
    return <MaintenancePage />;
  }

  return (
    <ConfigProvider
      theme={{
        cssVar: true,
        token: {
          colorPrimary: primaryColor,
          colorText: '#72788f',
          fontFamily: 'Figtree, sans-serif',
          lineHeight: 1.3,
        },
        components: {
          Input: {
            colorText: '#15161b',
          },
          Select: {
            colorText: '#15161b',
            controlHeight: '44px',
            optionHeight: '32px',
          },
          Modal: {
            paddingContentHorizontal: 0,
            paddingContentHorizontalLG: 0,
            paddingContentHorizontalSM: 0,
            paddingContentVertical: 0,
            paddingContentVerticalLG: 0,
            paddingContentVerticalSM: 0,
          },
          Button: {
            colorLink: primaryColor,
            colorLinkActive: primaryColor,
            colorLinkHover: primaryColor,
          },
        },
      }}
    >
      <Sentry.ErrorBoundary fallback={MyFallbackComponent}>
        <BrowserRouter history={history}>
          <Routes>
            <Route
              path={ROUTES?.MAIN} // the matching param will be available to the loader
              element={<Error404 />}
            />
            <Route
              path={`${ROUTES?.SLUG}/*`} // the matching param will be available to the loader
              element={<App />}
            />
          </Routes>
        </BrowserRouter>
      </Sentry.ErrorBoundary>
    </ConfigProvider>
  );
};
export default RoutesWrapper;
